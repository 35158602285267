function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
    });
  });
}

jQuery(function($) {

/*********************************************************************
  common
*********************************************************************/
slideout_menu();
$('.js-slide').slick({
  fade: true,
  autoplay:true,
  autoplaySpeed: 4000,
  speed:1500,
  slideToScroll:1,
  slidesToShow:1,
  arrows:false,
  dots: true,
});

$('.js-loopslide').infiniteslide({
  speed: 30,
  pauseonhover: false,
  responsive: true,
  });


$("#lightbox").wijlightbox();
$('#textposition').change(function () {
$("#lightbox").wijlightbox('option', 'textPosition', $(this).val());
});

/*********************************************************************
  index
*********************************************************************/


});
jQuery(window).on('load',function($){
});
jQuery(window).on('scroll',function($){
});
jQuery(window).on('resize',function($){
});



